import React from 'react'

function Footer() {
    return (
        <div className='footer'>
            <p className='m-0 py-3 text-center text-white'>All rights reserved 2022 | Developed by Kuvondikov</p>
        </div>
    )
}

export default Footer